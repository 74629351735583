<template>
  <div>
    <h5 class="text-md-left text-center">Datos de Beneficiarios</h5>
    <form>
      <div class="row mt-4">
        <div class="col-md-4">
          <div class="form-group">
            <label class="font-weight-normal">Nombre</label>
            <div class="position-relative">
              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="fea icon-sm icons feather feather-user">
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg><input v-model="nameBenefist" type="text" placeholder="Ingrese nombre :" class="form-control pl-5"
                name="nameBenefist" />
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="font-weight-normal">Parentesco</label>
            <div class="position-relative">
              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="fea icon-sm icons feather feather-user">
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg><input v-model="kinship" type="text" placeholder="Ingrese parentesco :" class="form-control pl-5"
                name="kinship" />
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="font-weight-normal">Beneficio ( % )</label>
            <div class="position-relative text-center">
              <input v-model="benefistPercent" type="text" class="form-control text-center" name="benefistPercent" />
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label></label>
            <div class="position-relative">
              <b-button @click="addBeneficiary()" class="btn btn-primary btn-sm mt-2">
                +
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="container mt-4">
      <b-table
        striped
        bordered
        borderless
        small
        hover
        no-border-collapse
        :items="items"
        :fields="fields"
      ></b-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        fields         : ["Nombre", "Parentesco", "Beneficio"],
        items          : [],
        nameBenefist   : "",
        kinship        : "",
        benefistPercent: "",
    };
  },

  methods: {
    addBeneficiary() {
      this.items.push({
        Nombre: this.nameBenefist,
        Parentesco: this.kinship,
        Beneficio: this.benefistPercent + " %",
      });
    },
  },
};
</script>